import { api } from "@app/api"

async function fetchPhotoDownloadURL({ id, selectedDownloadSettings }) {
  try {
    const { data } = await api().get(`/photos/download-photo/${id}/${selectedDownloadSettings}`)

    const downloadURL = data?.data?.url

    return downloadURL
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function fetchVideoDownloadURL({ id, selectedDownloadSettings }) {
  try {
    const { data } = await api().get(`/videos/download-video/${id}`)

    const downloadURL = data?.data?.url

    return downloadURL
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function fetchGalleryDownloadURL({ locationOrigin, galleryId, selectedDownloadSettings }) {
  try {
    const { data } = await api().get(`/download-gallery/${galleryId}/${selectedDownloadSettings}/`)

    // const downloadPath = data?.data?.url
    // const downloadURL = `${locationOrigin}${downloadPath}`
    const downloadURL = data?.data?.url

    return downloadURL
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function fetchSelectedScenesDownloadURL({ locationOrigin, selectedScenes, selectedDownloadSettings }) {
  try {
    const selectedScenesString = selectedScenes.reduce((accumulator, currentValue, index) => {
      const isLastItem = selectedScenes.length - 1 === index

      if (!isLastItem) {
        return accumulator + `scenes[]=${currentValue}&`
      }

      if (isLastItem) {
        return accumulator + `scenes[]=${currentValue}`
      }
    }, "")
    const { data } = await api().get(`/download-scenes/${selectedDownloadSettings}/?${selectedScenesString}`)

    // const downloadPath = data?.data?.url
    // const downloadURL = `${locationOrigin}${downloadPath}`
    const downloadURL = data?.data?.url

    return downloadURL
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function fetchFavoritePhotosDownloadURL({ locationOrigin, favoritesListId, selectedDownloadSettings }) {
  try {
    const { data } = await api().get(`/lists/${favoritesListId}/download-photos/${selectedDownloadSettings}/`)

    // const downloadPath = data?.data?.url
    // const downloadURL = `${locationOrigin}${downloadPath}`
    const downloadURL = data?.data?.url

    return downloadURL
  } catch (error) {
    console.error(error.response)

    return null
  }
}

export {
  fetchGalleryDownloadURL,
  fetchSelectedScenesDownloadURL,
  fetchFavoritePhotosDownloadURL,
  fetchPhotoDownloadURL,
  fetchVideoDownloadURL,
}
